<template>
  <div>
    <v-alert
      v-for="alert in alerts"
      :key="alert.content"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <Loading
      v-if="loading"
      :loading="loading"
      message="Loading Messages"
    />
    <v-card v-if="userThread">
      <v-card-title>
        <v-row>
          <v-col>
            {{ userThread.thread.subject }}
          </v-col>
          <v-col
            :align="$vuetify.breakpoint.xsOnly ? 'left' : 'right'"
            sm="6"
            cols="12"
          >
            <UserAvatarList :user-list="userThread.thread.users" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-list>
        <v-list-item
          v-for="message in userThread.thread.messages"
          :key="message.id"
          two-line
        >
          <router-link
            v-if="message.sender.username != profile.user"
            :to="{ name: 'SocialProfile', params: { username: message.sender.username }}"
          >
            <v-list-item-avatar class="align-self-start">
              <img
                :src="message.sender.profile.avatar_display_url"
                :alt="message.sender.username + ' avatar'"
                :to="{ name: 'SocialProfile', params: { username: message.sender.user }}"
              >
            </v-list-item-avatar>
          </router-link>
          <v-list-item-content :class="{'text-right': (message.sender.username == profile.user)}">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="message.content" />
            <!--eslint-enable-->
            <v-list-item-subtitle>
              {{ message.sender.username }} at {{ message.sent_at|formatDatetime }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-avatar
            v-if="message.sender.username == profile.user"
            class="align-self-start"
          >
            <img
              :src="message.sender.profile.avatar_display_url"
              :alt="message.sender.username + ' avatar'"
            >
          </v-list-item-avatar>
        </v-list-item>
      </v-list>
    </v-card>

    <v-row v-if="canReply">
      <v-col>
        <v-card>
          <v-card-title>
            Reply
          </v-card-title>
          <v-form
            ref="form"
            @submit.prevent="submit"
          >
            <v-card-text>
              <v-alert
                v-if="success"
                type="success"
              >
                Your message was successfully sent.
              </v-alert>
              <v-alert
                v-for="error in errorMessages"
                :key="error"
                type="error"
              >
                {{ error }}
              </v-alert>
              <v-textarea
                v-model="content"
                label="Message"
                auto-grow
                :disabled="submitting"
                :rules="messageContentRules"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="primary"
                type="submit"
                :disabled="submitting"
              >
                Send
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { parseResponseErrors } from '@/utils/errors'
import { mapState } from 'vuex'
import { messageContentRules } from '@/utils/rules'
import Loading from '@/components/Loading.vue'
import UserAvatarList from '@/components/UserAvatarList.vue'

export default {
  components: {
    Loading,
    UserAvatarList,
  },
  data: function() {
    return {
      alerts: [],
      loading: false,
      userThread: null,
      success: null,
      errorMessages: null,
      content: '',
      submitting: false,
      polling: null,
      messageContentRules: messageContentRules,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
    canReply() {
      return this.userThread && this.$online
    },
  },
  created: function () {
    this.loading = true
    this.getThread()
    this.autoRefresh()
  },
  beforeDestroy: function() {
    clearInterval(this.polling)
  },
  methods: {
    getThread() {
      this.$store.dispatch('messages/getAll').finally(() => {
        this.userThread = this.$store.getters['messages/getByID'](this.$route.params.id) || null
        if (!this.userThread) {
          clearInterval(this.polling)
          if (!this.$online) {
            this.alerts.push({
              type: 'error',
              message: 'An internet connection is required to download messages.',
            })
          } else {
            this.alerts.push({
              type: 'error',
              message: 'Failed to download messages.',
            })
          }
        } else {
          this.markRead()
        }
        this.loading = false
      })
    },
    autoRefresh() {
      this.polling = setInterval(() => {
        this.getThread()
      }, 1*30*1000)
    },
    markRead() {
      if (this.userThread && this.userThread.unread) {
        return allianceAPI.patch('/alliance/messages/threads/' + this.userThread.id + '/read/')
          .then(() => {
            // TODO Disable marking the message as read until profile caching is solved.
            //this.$store.dispatch('messages/replace', {replacementObject: response.data})
          })
          .catch((error) => {
            console.log('error marking thread as read!')
            console.log(error.response)
          })
      }
    },
    submit () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      const data = new FormData()
      data.append('content', this.content)
      return allianceAPI.post('/alliance/messages/threads/' + this.userThread.id + '/reply/', data)
        .then(() => {
          this.success = true
          this.$refs.form.reset()
          this.getThread()
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
  },
}
</script>
